import React, { Component } from 'react';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Row, css, styled, withTheme, up } from '@smooth-ui/core-sc';
import SvgIcon from '../../CustomComponents/SvgIcon/SvgIcon';

const CustomHeadroom = styled(Headroom)`
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`;

const HeaderWrapper = styled.div`
  background-color: #000;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 130px;
  width: 100%;

  ${up(
    'lg',
    css`
      height: 150px;
      .sui-grid,
      .sui-row {
        height: 150px;
      }
    `
  )}
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${up(
    'lg',
    css`
      flex-direction: column;
    `
  )}
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  max-width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 220px;
  img {
    height: auto;
    width: 218px;
  }
`;

class Header extends Component {
  render() {
    return (
      <header className="site-header">
        {/* Leave the above <header /> here, necessary to contain Headroom */}
        {/* Otherwise Headroom will wrap all sibling elements */}
        <CustomHeadroom disableInlineStyles downTolerance={10}>
          <HeaderWrapper>
            <Grid>
              <Row alignItems="flex-end" justifyContent="space-between">
                <LogoContainer>
                  <Link to="/">
                    <SvgIcon
                      icon="footer-logo"
                      scaleWidth="64"
                      scaleHeight="24"
                      width="218px"
                      height="82px"
                      fill="#9B9C9B"
                      title="Hewlett Packard Platinum Club"
                    />
                  </Link>
                </LogoContainer>
                <HeaderRightContainer>
                  {this.props.children}
                </HeaderRightContainer>
              </Row>
            </Grid>
          </HeaderWrapper>
        </CustomHeadroom>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

Header.defaultProps = {
  children: undefined,
  theme: undefined,
};

export default withTheme(Header);
